function disablingCheckbox(checkbox) {
  const targets = checkbox.getAttribute("data-disabling-checkbox-target").split(" ");
  const onState = checkbox.getAttribute("data-disabling-checkbox-hide-state") === "true";
  const currentState = checkbox.checked;

  targets.forEach((target) => {
    const targetElement = document.getElementById(target);

    if (currentState !== onState) {
      targetElement.style.display = "";
    } else {
      targetElement.style.display = "none";
    }
  });
}

function disablingCheckboxReady() {
  const checkboxes = document.querySelectorAll(".disabling-checkbox");
  checkboxes.forEach((checkbox) => {
    disablingCheckbox(checkbox);
    checkbox.addEventListener("change", () => {
      disablingCheckbox(this);
    });
  });
}

document.addEventListener("DOMContentLoaded", disablingCheckboxReady);
document.addEventListener("turbolinks:load", disablingCheckboxReady);
